import React from "react";
import { Button, Space, Table, Tag, Input, Spin, Tab  } from 'antd';
import { LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import { useState } from "react";
import { useEffect } from "react";

function TenantImprovementAllowace() {

    //setea lista en la tabla
const [dataSource, setDataSource] = useState([
    {
    id: "test",
    Name: "test",
    LOIStatus: "test",
    entity: "test,"
    },
    {
    id: "tast",
    Name: "tast",
    LOIStatus: "tast",
    entity: "tast,"
    },
    {
        id: "tist",
        Name: "tist",
        LOIStatus: "tost",
        entity: "tist,"
        },

])

const [loading, setLoading] = useState(true);
const [testingg, setTesttingg] = useState(true);


//make a reques on the table
useEffect(() => {
    setLoading(true);
   
    fetch('https://app.asana.com/api/1.0/projects/1206555923191929/tasks?opt_fields=name,assignee,completed,custom_fields,gid,parent,due_on',
    {
        method: 'GET',
        headers: {
            'Authorization': `Bearer 2/1206524890494959/1206900667183984:cb7dbca327758d58acea87a8a0d3590d`
        }
    }
)
    .then(response => {
      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      return response.json();
    })
    .then(data => {
      // Aquí puedes manipular los datos recibidos en formato JSON
      setDataSource([]);
      console.log(data);
      const acaseras = data.data;
      console.log(data.data[0]);
              
     const newMap = acaseras.map(DataHere => {   
        let tenantNameParse = DataHere.name === null ? "---" : DataHere.name;
        let staeCompleted = DataHere.completed === true ? "Completed" : "No Completed";
        const entityParse = DataHere.custom_fields[3].display_value === null ? "---" : DataHere.custom_fields[3].display_value;

        //sete las execution
        
       
            try {
              
                 
        setDataSource(prev=>{
            //const urlAncla = `href="https://app.asana.com/0/1202771100962765/${DataHere.gid}/f"` ;
            return [...prev, {
                key:DataHere.gid, 
                Id: DataHere.gid, 
                PropertyEntity: entityParse, 
                tenantName: tenantNameParse, 
                AmountDue: DataHere.custom_fields[1].display_value,
                dateON:DataHere.due_on,
                rentCommencement: "RentDataSimple[0]",
                 completed: staeCompleted    
                } ]} )
            
               
            } catch (error) {
                console.log(error)
            }
            
          });

setLoading(false);
})
    .catch(error => {
      console.error('Hubo un problema con la solicitud:', error);
    });
  
    console.log("hola por aca")

}, [testingg]);

const columns = [
    
    {
        title: 'Property Entity',
        dataIndex: 'PropertyEntity',
        key: 'Property Entity',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            
            return (
            <>
              <Input 
              autoFocus 
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e)=> {
                  
                  setSelectedKeys(e.target.value ? [e.target.value]:[])
              }}
              onPressEnter={()=> {
                  confirm()
              }}
              onBlur={()=> {
                  confirm()
              }}
              >
              </Input>
              <Button 
              type="primary"
              onClick={() => {
                  confirm();
              }} 
              >Search
              </Button>
              <Button onClick={() => {
                try {
                    clearFilters();
                    confirm();
                } catch (error) {
                    
                }
                 
              }} 
              danger>
                  Reset
              </Button>
            </>
            );
        },
        filterIcon:() => {
            return <SearchOutlined/>
        },
        onFilter: (value, record) => {
            try {
                return record.PropertyEntity.toLowerCase().includes(value.toLowerCase())
            } catch (error) {
                
            }
           
            
        },
        
    },
    {
        title: 'Tenant Name',
        dataIndex: 'tenantName',
        key: 'tenantName',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            
            return (
            <>
              <Input 
              autoFocus 
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e)=> {
                  
                  setSelectedKeys(e.target.value ? [e.target.value]:[])
              }}
              onPressEnter={()=> {
                  confirm()
              }}
              onBlur={()=> {
                  confirm()
              }}
              >
              </Input>
              <Button 
              type="primary"
              onClick={() => {
                  confirm();
              }} 
              >Search
              </Button>
              <Button onClick={() => {
                try {
                    clearFilters();
                    confirm();
                } catch (error) {
                    
                }
                 
              }} 
              danger>
                  Reset
              </Button>
            </>
            );
        },
        filterIcon:() => {
            return <SearchOutlined/>
        },
        onFilter: (value, record) => {
            try {
                return record.tenantName.toLowerCase().includes(value.toLowerCase())
            } catch (error) {
                
            }
           
            
        },     
    },
    {
        title: 'Amount Due',
        dataIndex: 'AmountDue',
        width: 200,
                
    },
    {
        title: 'Due Date',
        dataIndex: 'dateON',
        key: 'dateON',
        width: 150,        
},    
{
    title: 'Is Completed',
    dataIndex: 'completed',
    key: 'completed',
    width: 150,
   filters: [
        {
            text:"Completed",
            value:"Completed",
        },
        {
            text:"No Completed",
            value:"No Completed",
        },
        
    ],
    onFilter: (value, record) => {
        try {
           return record.completed.indexOf(value) === 0
        } catch (error) { 
    }
    
}},

{
    title: 'Link',
    dataIndex: 'Id',
    key: 'Id',
    width: 100,
    render: (text) => <a href={'https://app.asana.com/0/1202771100962765/' + text + '/f'} target="_blank">View task</a>,
},

]








const onChange = (pagination, filters) => {
    console.log('params', pagination, filters);
  };
    

    return (
        <>
        <h1>Tenant Improvement Allowace</h1>
        <Spin 
        tip="Loading..." 
        spinning={loading} 
        size="large"
        className="splinLoad"
        
        >
        {loading ? '' : <Table   columns={columns} dataSource={dataSource} onChange={onChange} /> }
        </Spin>
       
        </>
    )
    
}


export { TenantImprovementAllowace }