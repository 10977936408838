import React from "react";
import { Button, Space, Table, Tag, Input, Spin,  } from 'antd';
import { LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import { useState } from "react";
import { useEffect } from "react";

function TestTab() {

const [dataSource, setDataSource] = useState([
    {
    id: "test",
    Name: "test",
    LOIStatus: "test",
    entity: "test,"
    },
    {
    id: "tast",
    Name: "tast",
    LOIStatus: "tast",
    entity: "tast,"
    },
    {
        id: "tist",
        Name: "tist",
        LOIStatus: "tost",
        entity: "tist,"
        },

])

const [loading, setLoading] = useState(true);
const [testingg, setTesttingg] = useState(true);


function miFuncion() {
    
}


useEffect(() => {
    setLoading(true);
   
    fetch('https://app.asana.com/api/1.0/projects/1202771100962765/tasks?opt_fields=name,assignee,completed,custom_fields,gid,parent,memberships',
    {
        method: 'GET',
        headers: {
            'Authorization': `Bearer 2/1206524890494959/1206900667183984:cb7dbca327758d58acea87a8a0d3590d`
        }
    }
)
    .then(response => {
      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      return response.json();
    })
    .then(data => {
      // Aquí puedes manipular los datos recibidos en formato JSON
      setDataSource([]);
      console.log(data);
      const acaseras = data.data;

      
                   
     const newMap = acaseras.map(DataHere => {
       
           
            try {
                const conviertiendoesto = DataHere.custom_fields[0].enum_value;
                const enumDate = conviertiendoesto.name;
                 
        setDataSource(prev=>{
            const urlAncla = `href="https://app.asana.com/0/1202771100962765/${DataHere.gid}/f"` ;
            return [...prev, {key:DataHere.gid, Id: DataHere.gid, Name: DataHere.name, LOIStatus: DataHere.custom_fields[0].display_value, entity: DataHere.custom_fields[1].display_value, Tenant: DataHere.custom_fields[2].display_value     } ]} )
            
               
            } catch (error) {
                
            }
            
          });

setLoading(false);
})
    .catch(error => {
      console.error('Hubo un problema con la solicitud:', error);
    });
  
    console.log("hola por aca")

}, [testingg]);

const columns = [
    
    {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name'
    },
    {
        title: 'LOI Status',
        dataIndex: 'LOIStatus',
        key: 'LOI Status',filters: [
            {
                text:"Tenant's Court",
                value:"Tenant's Court",
            },
            {
                text:"Landlord's Court",
                value:"Landlord's Court",
            },
            {
                text:"Buyer's Court",
                value:"Buyer's Court",
            },
            {
                text:"Seller's Court",
                value:"Seller's Court",
            },
            {
                text:"Fully Executed",
                value:"Fully Executed",
            }
        ],
        onFilter: (value, record) => {
            try {
               return record.LOIStatus.indexOf(value) === 0
            } catch (error) { 
        }
    },
        
    },
    {
        title: 'Entity',
        dataIndex: 'entity',
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            
            return (
            <>
              <Input 
              autoFocus 
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e)=> {
                  
                  setSelectedKeys(e.target.value ? [e.target.value]:[])
              }}
              onPressEnter={()=> {
                  confirm()
              }}
              onBlur={()=> {
                  confirm()
              }}
              >
              </Input>
              <Button 
              type="primary"
              onClick={() => {
                  confirm();
              }} 
              >Search
              </Button>
              <Button onClick={() => {
                try {
                    clearFilters();
                    confirm();
                } catch (error) {
                    
                }
                 
              }} 
              danger>
                  Reset
              </Button>
            </>
            );
        },
        filterIcon:() => {
            return <SearchOutlined/>
        },
        onFilter: (value, record) => {
            try {
                return record.entity.toLowerCase().includes(value.toLowerCase())
            } catch (error) {
                
            }
           
            
        },        
    },
    {
        title: 'Tenant',
        dataIndex: 'Tenant',
        key: 'Tenant'
    },
    {
    title: 'Link',
    dataIndex: 'Id',
    key: 'Id',
    render: (text) => <a href={'https://app.asana.com/0/1202771100962765/' + text + '/f'} target="_blank">View task</a>,
},

]








const onChange = (pagination, filters) => {
    console.log('params', pagination, filters);
  };
    

    return (
        <>
        <h1>LOls</h1>
        <Spin 
        tip="Loading..." 
        spinning={loading} 
        size="large"
        className="splinLoad"
        
        >
        {loading ? '' : <Table columns={columns} dataSource={dataSource} onChange={onChange} /> }
        </Spin>
       
        </>
    )
    
}

export { TestTab }
