import logo from './logo.svg';
import './App.css';
import { TestTab } from "./components/TestTab";
import { PropertyIssues } from './components/PropertyIssues ';
import { Properties } from './components/Properties';
import { useState } from "react";
import { useEffect } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Layout, Menu, theme } from 'antd';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Lol´s', '1',  <PieChartOutlined />),
  getItem('Properties', '2', <ShopOutlined />),
  
];

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [lolsisActive, setLolsisActive] = useState(true);
  const [propertiesActive, setPropertiesActive] = useState (false);
  const {
    token: {  borderRadiusLG,   },
  } = theme.useToken();

  function estaCambiando (e) {
    console.log(e)
if (e.key === '2') {
  setLolsisActive(false);
  setPropertiesActive(true);
}else if (e.key === '1'){
  setLolsisActive(true);
  setPropertiesActive(false);
}
 else {
  
  
  
}
  }

  return (
<ConfigProvider
theme={{
  components: {
    Menu: {
      collapsedWidth: 200,
     
  }
},
  token: {

   
    colorPrimary: '#c0a870',
    siderBg: '#000',
    darkItemBg: '#fff',
    triggerBg: '#fff',
    lightSiderBg: '#000',
    }
}}
>

    <Layout
    style={{
      minHeight: '100vh',
    }}
  >
    <Sider 
    collapsible collapsed={collapsed} 
    onCollapse={(value) => setCollapsed(value)}
    style={{
     
      
     
      background: '#000',
    }}
    >
      <div style={{
        height: '48px',
      }} >
        <h2>Hola</h2>
        </div>


      <Menu 
      style={{
        background: '#000'
      }}
      theme="dark" 
      defaultSelectedKeys={['1']} 
      mode="inline" 
      items={items} 
      onClick={estaCambiando}/>
 
    </Sider>
    <Layout>
      
      <Content
        style={{
          margin: '0 16px',
          
        }}
      >
       
        <div
        className='App-header'
          
        >
         
    
      
    
      {lolsisActive && <TestTab />}
      
      {propertiesActive && <Properties />}
      
    
  
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Victory Real State ©{new Date().getFullYear()} Created by 3Fuse
      </Footer>
    </Layout>
  </Layout>
  
 </ConfigProvider>


  );
}

export default App;
